import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import { getAllowedClasses } from '../../library/utils'

/**
 * 
 * @param {Object}      props
 * @param {String}      props.faq               Pregunta frecuente
 * @param {String}      props.answer            Respuesta a la pregunta frecuente
 * @param {Boolean}     props.collapsible       Indica si tendrá la funcionalidad de colapsar para evitar mostrar mucho contenido
 * @param {String}      props.className         Clases adicionales
 * @returns {React.Component}
 */
function FAQ({faq, answer, collapsible, collapsed = true, className}) {
    const [isCollapsed, seIsCollapsed] = useState(collapsed)
    const faqRef = useRef(null)

    const toggleCollapse = () => {
        seIsCollapsed(isCollapsed => !isCollapsed)
    }

    const plusSVG = () => {
        return <svg width="25" height="21" viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 3L18 18L33 3" stroke="#837FF3" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    }
    
    const minusSVG = () => {
        return <svg width="25" height="21" viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33 18L18 3L3 18" stroke="#837FF3" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    }
    
    const classList = getAllowedClasses({
        'faq': true,
        'faq--collapsible': collapsible,
        'faq--collapsed': isCollapsed
    })

    const additionalAnswerProps = {}
    if(typeof answer === 'string'){
        additionalAnswerProps.dangerouslySetInnerHTML = {__html: answer}
    }else{
        additionalAnswerProps.children = answer
    }

    useEffect(() => {
        const stopClickPropagation = (evt) => {
            evt.stopPropagation()
        }

        if(faqRef && faqRef.current && faqRef.current.querySelectorAll('a')){
            faqRef.current.querySelectorAll('a').forEach(element => {
                element.addEventListener('click', stopClickPropagation)
            }); 
        }

        return () => {
            if(faqRef && faqRef.current && faqRef.current.querySelectorAll('a')){
                faqRef.current.querySelectorAll('a').forEach(element => {
                    element.removeEventListener('click', stopClickPropagation)
                }); 
            }
        }
    }, [])

    return <div ref={faqRef} className={`${className ? className : ''} ${classList}`} onClick={toggleCollapse}>
        <div className="faq__question">
            { faq }
            { isCollapsed ? plusSVG() : minusSVG() }
        </div>
        <div className="faq__answer" {...additionalAnswerProps}/>
    </div>
}

FAQ.defaultProps = {
    collapsible: true,
    collapsed: true,
}

FAQ.propTypes = {
    faq: PropTypes.string,
    answer: PropTypes.string,
    collapsible: PropTypes.bool,
    className: PropTypes.string,
    collapsed: PropTypes.bool
}

export default FAQ

