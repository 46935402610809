import React from 'react'
import PropTypes from 'prop-types'
import { getAllowedClasses, getRealSrc } from '../../library/utils'

/**
 * Muestra un listado de logos
 * 
 * @author Abraham Flores
 * @param {Object}  props
 * @param {Object}  props.children              Hijos del contenedor de logos, se muestran debajo de los logos
 * @param {Number}  props.cols                  Indica si se partirá en columnas, por ahora disponibles 2, 3 y 4
 * @param {Array}   props.logos                 Listado de logos, cada elemento es un objeto con la estructura {src, alt, height} donde height es opcional y sobreescribe al height general
 * @param {String}  props.className             Clases adcionales para el componente
 * @param {Boolean} props.hoverable             Indica si se mostrará el logo original al pasar sobre el, se aplica filtro grises
 * @param {Boolean} props.separated             Indica si se aplicará una mayor separación entre iconos
 * @param {Boolean} props.separatedX2           Indica si se aplicará una mayor separación doble entre iconos
 * @returns 
 */
const Logos = ({ children, height = 100, cols = null, separated, separatedX2, logos = [], hoverable, className = '', gap = null, ...attrs}) => {
    const validatedCols = [2, 3, 4].includes(cols) ? cols : null
    const classList = getAllowedClasses({
        'logos': true,
        'logos-two-cols': validatedCols == 2,
        'logos-three-cols': validatedCols == 3,
        'logos-four-cols': validatedCols == 4,
    })

    return <section style={gap ? {gap}  : {}} className={`${className ? className : ''} ${classList}`} {...attrs}>
        { logos.map(({ src, alt, height: logoHeight, link }) => <div className={`logos__logo ${separated ? 'logos__logo--separated' : ''} ${separatedX2 ? 'logos__logo--separated-x2' : ''} ${hoverable ? 'logos__logo--hoverable' : ''}`}>
            {!link && <img alt={alt} src={getRealSrc(src)} style={logoHeight || height ? {height: `${logoHeight || height}px`} : {}} height={logoHeight || height}/>}
            {link && <a href={link} target="_blank">
                <img alt={alt} src={getRealSrc(src)} style={logoHeight || height ? {height: `${logoHeight || height}px`} : {}} height={logoHeight || height}/>
            </a>}
        </div>) }
        { children }
    </section>
}

Logos.propTypes = {
    height: PropTypes.number,
    logos: PropTypes.arrayOf(PropTypes.shape({
        src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        alt: PropTypes.string,
        height: PropTypes.number,
        link: PropTypes.string
    })),
    children: PropTypes.any,
    className: PropTypes.string,
    hoverable: PropTypes.bool
}

export default Logos