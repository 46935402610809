import React from 'react'
import { Feature, Heading, Image } from '../../components'

export default function Benefits({title = null, benefits = []}) {
    return <section className="md:px-10 lg:px-20 2xl:px-40 2xl:py-20 py-10 section-padding">
        <Heading h2 className="text-center text-xl md:text-3xl3 2xl:text-4xl4">{ title }</Heading>

        <div className="flex flex-wrap sm:flex-nowrap justify-center items-center mt-12">
            {benefits.map(({title, alt, src}, index) => <div key={index} className="mx-4 w-80 mb-5 sm:mb-0">
                <Feature icon={src}>{ title }</Feature>
            </div>)}
        </div>
    </section>
}