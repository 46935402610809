import React from 'react'
import { Heading, Image, Paragraph } from '../../components'
import flag from '../../assets/images/flag.png'

export default function Advisor({image, title, description}) {
    return <section className="flex flex-col-reverse sm:flex-row px-10 lg:px-20 2xl:px-40 2xl:py-10 py-5">
        <div className="w-full sm:w-1/2 p-10 px-16">
            <Image src={image}/>
        </div>
        <div className="w-full sm:w-1/2 flex flex-col justify-center">
            <Heading className="w-full text-center sm:text-left text-3xl2 md:text-5xl3 2xl:text-7xl3" h1>{ title }</Heading>
            <div className="flex mt-8 items-center">
                <div className="hidden sm:block w-32">
                    <Image src={flag}/>
                </div>
                <Paragraph className="ml-0 pr-0 sm:ml-7 sm:pr-10 text-xl md:text-2xl2 2xl:text-4xl" dangerouslySetInnerHTML={{__html: description}}></Paragraph>
            </div>
        </div>
    </section>
}
