import React from 'react'
import { Button, CTACard, Heading, Image, Link, Paragraph } from '../../components'

export default function Solutions({title, solutions = []}) {
    return <section className="px-10 lg:px-20 2xl:px-40 py-5">
        <Heading h2 className="text-center m-0 text-xl md:text-3xl3 2xl:text-4xl4">{ title }</Heading>
        <div className="pt-5 sm:pt-10 flex flex-col sm:flex-row justify-center">
            {solutions.map(({icon, title, description, ctaText, ctaLink}, index) => <div key={index} className="flex justify-center flex-col md:flex-row">
                <div className="md:w-6/12 flex justify-center items-center">
                    <Image src={icon} className="w-6/12 md:w-10/12"/>
                </div>
                <div className="md:w-6/12 p-5 md:pl-16">
                    <Heading h2 className="m-0 text-xl md:text-3xl3 2xl:text-4xl4">{ title }</Heading>
                    <Paragraph className="text-lg md:text-2xl2 2xl:text-4xl mb-4">{ description }</Paragraph>
                    <Button small transparent secondary link={ctaLink}>{ ctaText }</Button>
                </div>
            </div>)}
        </div>
    </section>
}
