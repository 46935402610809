import { graphql } from 'gatsby'
import React, { useEffect } from 'react'
import Header from '../components/organisms/Header'
import Hero from './HomePage/Hero'
import Companies from './HomePage/Companies'
import Invoices from './HomePage/Invoices'
import Solutions from './HomePage/Solutions'
import Advisor from './HomePage/Advisor'
import Benefits from './HomePage/Benefits'
import Contact from './HomePage/Contact'
import Footer from '../components/organisms/Footer'
import MainLayout from './Layouts/MainLayout'
import Logos from '../components/organisms/Logos'
import Avansi from './HomePage/Avansi'
import FAQ from '../components/molecules/FAQ'
import FAQSSection from '../components/sections/FAQSSection'

const HomePage = ({ data }) => {
    const { frontmatter } = data.markdownRemark
    const menuName = frontmatter.menu ? frontmatter.menu: 'main'
    const topLabelContent = frontmatter.header.topContent ? frontmatter.header.topContent : null
    const heroTitle = frontmatter.header.heroTitle ? frontmatter.header.heroTitle : null
    const heroDescription = frontmatter.header.heroDescription ? frontmatter.header.heroDescription : null
    const heroCTAText = frontmatter.header.heroCTAText ? frontmatter.header.heroCTAText : null
    const heroImgFluid = frontmatter.header.heroImage.image.childImageSharp.fluid
    const companiesTitle = frontmatter.companies.title ? frontmatter.companies.title : null
    const logos = frontmatter.companies.logos ? frontmatter.companies.logos.map(logo => ({
        src: logo.image.childImageSharp.fluid.src,
        alt: logo.alt
    })) : []
    const alliesTitle = frontmatter.allies.title ? frontmatter.allies.title : null
    const alliesLogos = frontmatter.allies.logos ? frontmatter.allies.logos.map(logo => ({
        src: logo.image.childImageSharp.fluid.src,
        alt: logo.alt
    })) : []
    const invoiceImgFluid = frontmatter.invoices.image.image.childImageSharp.fluid
    const invoiceTitle = frontmatter.invoices.title ? frontmatter.invoices.title : null
    const invoiceDescription = frontmatter.invoices.description ? frontmatter.invoices.description : null
    const automatizationImgFluid = frontmatter.automatization.image.image.childImageSharp.fluid
    const automatizationTitle = frontmatter.automatization.title ? frontmatter.automatization.title : null
    const automatizationDescription = frontmatter.automatization.description ? frontmatter.automatization.description : null
    const solutionsTitle = frontmatter.solutions.title ? frontmatter.solutions.title : null
    const solutions = frontmatter.solutions.items ? frontmatter.solutions.items.map(item => ({
        icon: item.icon.image.childImageSharp.fluid.src,
        title: item.title,
        description: item.description,
        ctaText: item.ctaText,
        ctaLink: item.ctaLink
    })) : []
    const advisorImgFluid = frontmatter.advisor.advisorImage.image.childImageSharp.fluid
    const advisorTitle = frontmatter.advisor.title ? frontmatter.advisor.title : null
    const advisorDescription = frontmatter.advisor.description ? frontmatter.advisor.description : null
    const benefitsTitle = frontmatter.benefits.title? frontmatter.benefits.title: null
    const benefits = frontmatter.benefits.items ? frontmatter.benefits.items.map(item => ({
        title: item.title,
        alt: item.icon.alt,
        src: item.icon.image.childImageSharp.fluid.src,
    })) : []
    const contactTitle = frontmatter.contact.title? frontmatter.contact.title: null

    return <MainLayout seo={frontmatter.seo}>
        <Header menu={menuName} topLabelContent={topLabelContent} fixed={frontmatter.header.fixed}></Header>
        <Hero title={heroTitle} description={heroDescription} ctaText={heroCTAText} image={heroImgFluid.src}></Hero>
        <Benefits title={benefitsTitle} benefits={benefits}></Benefits>
        <Companies title={companiesTitle} logos={logos}></Companies>
        <Invoices image={invoiceImgFluid.src} title={invoiceTitle} description={invoiceDescription}></Invoices>
        <Invoices reverse image={automatizationImgFluid.src} title={automatizationTitle} description={automatizationDescription}></Invoices>
        <Companies title={alliesTitle} logos={alliesLogos}></Companies>
        <Solutions title={solutionsTitle} solutions={solutions}></Solutions>
        <Avansi {...frontmatter.avansi}></Avansi>
        <Advisor image={advisorImgFluid.src} title={advisorTitle} description={advisorDescription}></Advisor>
        <FAQSSection {...frontmatter.faqs}/>
        <Logos {...frontmatter.media}></Logos>
        <Contact title={contactTitle}></Contact>
        <Footer></Footer>
    </MainLayout>
}

export default HomePage

export const HomeQuery = graphql`
    query HomePage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                menu
                header{
                    topContent
                    fixed
                    heroTitle
                    heroDescription
                    heroCTAText
                    heroImage{
                        image{
                            childImageSharp {
                                fluid{
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                companies{
                    title
                    logos{
                        alt
                        image{
                            childImageSharp {
                                fluid{
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                allies{
                    title
                    logos{
                        alt
                        image{
                            childImageSharp {
                                fluid{
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                invoices{
                    image{
                        image{
                            childImageSharp {
                                fluid{
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        alt
                    }
                    title
                    description 
                }
                automatization{
                    image{
                        image{
                            childImageSharp {
                                fluid{
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        alt
                    }
                    title
                    description 
                }
                solutions{
                    title
                    items{
                        title
                        description
                        ctaLink
                        ctaText
                        icon{
                            image{
                                childImageSharp {
                                    fluid{
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            alt
                        }
                    }
                }
                avansi{
                    title
                    description
                    ctaLink
                    ctaText
                }
                advisor{
                    advisorImage{
                        image{
                            childImageSharp {
                                fluid{
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        alt
                    }
                    title
                    showFlag
                    description
                }
                faqs{
                    faqs{
                        faq
                        answer
                    }
                    heading
                    enable_schema
                }
                benefits{
                    title
                    items{
                        title
                        icon{
                            image{
                                childImageSharp {
                                    fluid{
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            alt
                        }
                    }
                }
                media{
                    height
                    gap
                    logos{
                        height
                        src{
                            childImageSharp {
                                fluid{
                                    ...GatsbyImageSharpFluid
                                }
                            }
                            publicURL
                        }
                        alt
                        link
                    }
                    hoverable
                }
                contact{
                    title
                }
                seo{
                    title
                    description
                    keywords
                    language
                    canonical
                    #schemas{
                    #    schemas
                    #}
                    lang
                }
            }
        }
    }
`

/**
 * seo {
                metatags {
                    title
                    description
                    keywords
                    language
                    hreflang_links
                }
                schemas {
                    schemas
                }
            }
 */