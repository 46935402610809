import React, { useEffect } from 'react'
import { Button, Heading, Image, Paragraph } from '../../components'
import { HS_CONTACT_FORM } from '../../config/hubspot-forms'
import HubspotModalLauncher from '../../library/HubspotModalLauncher/HubspotModalLauncher'

export default function Hero({title, description, ctaText, ctaLink, image}) {
    useEffect(() => {
        let interval = setInterval(() => {
            if(typeof hbspt !== 'undefined') {
                window.hbspt.cta.load(6180490, 'b173898b-a53b-491d-8904-f1e596bc117e', {"useNewLoader":"true","region":"na1"});
                clearInterval(interval)
                console.log('limpiando')
            }
        }, 400) 
    }, [])

    return <section className="flex-wrap w-full px-10 lg:px-20 2xl:px-40 flex lg:h-screen items-center">
        <div className="w-full sm:w-6/12 flex xl:self-stretch">
            <div className="flex flex-col justify-center">
                <Heading h1 className="text-3xl2 md:text-5xl3 2xl:text-7xl">{ title }</Heading>
                <Paragraph className="mt-2 text-sm md:text-2xl 2xl:text-2xl" dangerouslySetInnerHTML={{__html: description}}></Paragraph>
                <div className="flex items-center mt-5 flex-wrap">
                    <Button secondary className="w-full sm:w-auto mt-2" onClick={() => {
                        HubspotModalLauncher.open(HS_CONTACT_FORM)
                    }}>{ ctaText }</Button>

                    <Button secondary className="w-full sm:w-auto ml-2 mt-2">
                        <span className="hs-cta-wrapper" id="hs-cta-wrapper-b173898b-a53b-491d-8904-f1e596bc117e">
                            <span className="hs-cta-node hs-cta-b173898b-a53b-491d-8904-f1e596bc117e" id="hs-cta-b173898b-a53b-491d-8904-f1e596bc117e">
                                <a className="text-white" href="https://cta-redirect.hubspot.com/cta/redirect/6180490/b173898b-a53b-491d-8904-f1e596bc117e" target="_blank" rel="noopener">
                                    CERTIFICACIÓN DGII
                                </a>
                            </span>
                        </span>
                    </Button>
                </div>

            </div>
        </div>
        <div className="w-full max-w-full sm:w-6/12 pl-10 flex items-center sm:items-end content-between">
            <Image src={image}/>
        </div>
    </section>
}
