import React from 'react'
import { Heading, Image } from '../../components'

export default function Companies({title = null, logos = []}) {
    return <section className="2xl:py-20 sm:px-10 lg:px-20 2xl:px-40 px-20 py-10">
        <Heading h3 className="text-center text-xl md:text-2xl2 2xl:text-4xl">{ title }</Heading>

        <div className="flex justify-center items-center mt-12 flex-wrap">
            {logos.map(({alt, src}, index) => <div key={index} className="mx-8 mb-3 md:mx-11">
                <Image src={src} alt={alt} className="h-10 2xl:h-14 max-w-max"/>
            </div>)}
        </div>
    </section>
}
