import React from 'react'
import { Heading, Image, Paragraph } from '../../components'
import { getAllowedClasses } from '../../library/utils'

export default function Invoices({reverse, image, title,  description}) {
    const obj = {
        'flex-col-reverse': true,
        'sm:flex-row': !reverse,
        'sm:flex-row-reverse': reverse,
    }
    const classList = getAllowedClasses(obj)

    const imageClassList = getAllowedClasses({
        'pr-0': !reverse,
        'sm:pr-20': !reverse,
        'pl-0': reverse,
        'sm:pl-20': reverse,
    })

    return <section className={`flex px-10 lg:px-20 2xl:px-40 py-5 ${classList}`}>
        <div className={`w-full sm:w-1/2 ${imageClassList}`}>
            <Image src={image} className="w-full sm:w-10/12"/>
        </div>
        <div className="w-full sm:w-1/2 flex flex-col justify-center">
            <Heading className="w-full text-center sm:text-left text-2xl md:text-3xl2 2xl:text-4xl4" h2>{ title }</Heading>
            <Paragraph className="text-center sm:text-left mt-7 text-lg md:text-2xl2 2xl:text-4xl" dangerouslySetInnerHTML={{__html: description}}></Paragraph>
        </div>
    </section>
}
