import React from 'react'
import PropTypes from 'prop-types'
import FAQ from '../molecules/FAQ'
import Heading from '../atoms/Heading'
import Helmet from 'react-helmet'

const FAQsSchema = ({faqs}) => {
    const schemaInit = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": []
    }

    const faqsForSchema = faqs.map((faq, answer) => ({
        "@type": "Question",
        "name": faq,
        "acceptedAnswer": {
            "@type": "Answer",
            "text": answer
        }
    }))

    schemaInit.mainEntity = faqsForSchema

    return <Helmet>
        {/* Schema.org tags */}
        <script type="application/ld+json">{JSON.stringify([schemaInit])}</script>
    </Helmet>
}

const FAQSSection = ({heading, faqs, enable_schema}) => {
    return <section className="faqs section-padding">
        <Heading h2 className="faqs-heading">{ heading }</Heading>
        <div>
            { faqs.map((faq, index) => <FAQ key={index} {...faq}/>) }    
        </div>

        {enable_schema && <FAQsSchema faqs={faqs}/>}
    </section>
}

FAQSSection.defaultProps = {
    FAQSSection: false
}

FAQSSection.propTypes = {
    faqs: PropTypes.arrayOf(PropTypes.shape({
        faq: PropTypes.string,
        answer: PropTypes.string,
    })),
    enable_schema: PropTypes.bool,
}

export default FAQSSection

